import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { ROUTE_ABOUT, ROUTE_HOME, ROUTE_MY_POSTS, ROUTE_TO_PLAY } from '../../constant'
import { theme } from '../../styles/theme'

interface UseMenu {
  onClickHome: Function
  onClickMyPosts: Function
  onClickAboutMe: Function
  onClickToPlay: Function
  homeButtonProps: object
  myPostsButtonProps: object
  aboutButtonProps: object
  toPlayButtonProps: object
}

export const useMenu = (): UseMenu => {
  const navigate = useNavigate()

  // @ts-ignore
  const route = useSelector((state) => state.route)

  const onClickHome = () => navigate(ROUTE_HOME)
  const onClickMyPosts = () => navigate(ROUTE_MY_POSTS)
  const onClickAboutMe = () => navigate(ROUTE_ABOUT)
  const onClickToPlay = () => navigate(ROUTE_TO_PLAY)

  const homeButtonProps = route === ROUTE_HOME ? { backgroundColor: theme.palette.primary.dark } : {}
  const myPostsButtonProps = route === ROUTE_MY_POSTS ? { backgroundColor: theme.palette.primary.dark } : {}
  const aboutButtonProps = route === ROUTE_ABOUT ? { backgroundColor: theme.palette.primary.dark } : {}
  const toPlayButtonProps = route === ROUTE_TO_PLAY ? { backgroundColor: theme.palette.primary.dark } : {}

  return {
    onClickHome,
    onClickMyPosts,
    onClickAboutMe,
    onClickToPlay,
    homeButtonProps,
    myPostsButtonProps,
    aboutButtonProps,
    toPlayButtonProps,
  }
}
