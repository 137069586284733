import styled from '@emotion/styled'
import { Grid } from '@mui/material'
import { Content, ProfileBox } from '../components'
import { faRuler, faGraduationCap, faHouseChimney, faBullhorn } from '@fortawesome/free-solid-svg-icons'

interface Props {
  pair?: boolean
}

const TITLE = 'PROFESSIONAL PORTFOLIO'
const SUBTITLE = `some projects I've been part of`

const DATA = [
  {
    id: 4,
    name: 'Thoropass',
    description: 'The complete compliance solution: Make compliance a way to win',
    url: 'https://heylaika.com/',
    icon: faRuler,
  },
  {
    id: 3,
    name: 'UDR',
    description:
      'Application to search and have a virtual tour of the department. Also I worked in an application to manage everything related to living in these luxury apartments',
    url: 'https://www.udr.com/',
    icon: faHouseChimney,
  },
  {
    id: 2,
    name: 'ed-ready',
    description: 'Web application to apply test and validate knowledge, to prepare students for college and career',
    url: 'https://edready.org/home',
    icon: faGraduationCap,
  },
  {
    id: 1,
    name: 'Advertising campaigns',
    description:
      'Attractive advertising campaigns, creation of visual elements and inclusion of visual elements in existing applications',
    url: 'https://www.prodigious.com/our-work',
    icon: faBullhorn,
  },
]

export const ProfessionalPortfolio = ({ pair = false }: Props) => {
  return (
    <Content pair={pair} title={TITLE} subtitle={SUBTITLE}>
      <SectionContent container direction={{ xs: 'column', sm: 'row' }} padding={{ xs: '25px', sm: '50px' }}>
        {DATA.map(({ id, name, icon, description, url }) => (
          <ProfileBox key={id} name={name} description={description} url={url} icon={icon} />
        ))}
      </SectionContent>
    </Content>
  )
}

const SectionContent = styled(Grid)`
  justify-content: space-around;
`
