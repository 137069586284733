import { Button, Grid, Typography } from '@mui/material'
import styled from '@emotion/styled'

// @ts-ignore
import logo from '../../assets/logo.png'
import { ImageViewer } from '../ImageViewer'
import LanguageSelector from '../LanguageSelector'
import { useMenu } from './useMenu'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLeftLong } from '@fortawesome/free-solid-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

const Menu = () => {
  const {
    aboutButtonProps,
    homeButtonProps,
    myPostsButtonProps,
    onClickAboutMe,
    onClickHome,
    onClickMyPosts,
    onClickToPlay,
    toPlayButtonProps,
  } = useMenu()

  return (
    <MenuContainer container display={{ xs: 'none', sm: 'flex' }}>
      <Content
        container
        justifyContent={'space-between'}
        margin={{ xs: '0 20px', md: 'auto' }}
        flexDirection={{ xs: 'column-reverse', sm: 'row' }}
        height="80px"
      >
        <Grid
          container
          // sm={8}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Grid item>
            <StyledButton style={{ ...homeButtonProps }} variant="text" onClick={() => onClickHome()}>
              <StyledFontAwesomeIcon icon={faLeftLong as IconProp} />
              go to roinercamacho.com
            </StyledButton>
            <StyledButton style={{ ...myPostsButtonProps }} variant="text" onClick={() => onClickMyPosts()}>
              my posts
            </StyledButton>
            <StyledButton style={{ ...toPlayButtonProps }} variant="text" onClick={() => onClickToPlay()}>
              to play
            </StyledButton>
            <StyledButton style={{ ...aboutButtonProps }} variant="text" onClick={() => onClickAboutMe()}>
              about me
            </StyledButton>
          </Grid>
          <Grid item>
            <ImageViewer src={logo} size={'30px'} />
          </Grid>
        </Grid>
      </Content>
    </MenuContainer>
  )
}

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  color: #fff;
  margin-right: 10px;
`

const MenuContainer = styled(Grid)`
  background-color: #e0a80d;
  box-shadow: 0 4px 20px 0 #856717;
  position: sticky;
  top: 0;
  z-index: 1;
`

const Content = styled(Grid)`
  max-width: 1300px;
  margin: auto;
`

const StyledButton = styled(Button)`
  color: white;
  font-family: 'Lato', sans-serif;
  font-size: 28px;
  padding: 0 10px;
  text-transform: lowercase;
  margin-left: 25px;

  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.light};
  }
`

const StyledPhrase = styled(Typography)`
  color: white;
  font-family: 'Lato', sans-serif;
  margin-right: 10px;
`

export default Menu
