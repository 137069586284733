import {CHANGE_FILTER_LANGUAGE, CHANGE_FILTER_TAG} from "./actions";

interface ChangeLanguageProps {
    language: string
}

interface ChangeTagProps {
    id: number
}

export const updateFilterLanguage = ({ language }: ChangeLanguageProps) => {
    return{
        type: CHANGE_FILTER_LANGUAGE,
        payload : language,
    };
};

export const updateFilterTag = ({ id }: ChangeTagProps) => {
    return{
        type: CHANGE_FILTER_TAG,
        payload : id,
    };
};