import {Article} from "../types";
import data from "../data/home/home.json";

interface GetArticlesValues {
    articles: Article[]
}

export const getArticles = (): GetArticlesValues => {
    const articles = data.read_articles

    return {
        articles
    }
}