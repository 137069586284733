import styled from '@emotion/styled'
import { Grid, Typography } from '@mui/material'

interface Props {
  info: {
    id: number
    title: string
    content: string
  }
  size?: number
}

export const SecondaryElement = ({ info, size = 4 }: Props) => {
  const { id, title, content } = info
  return (
    <Content key={id} item xs={12} sm={size}>
      <Title variant="body2">{title}</Title>
      <ContentLabel variant="body2">{content}</ContentLabel>
    </Content>
  )
}

const Content = styled(Grid)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const Title = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.secondary};
`

const ContentLabel = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.secondary};
`
