import { useParams } from 'react-router-dom'
import { Silla } from '../../types'

interface Values {
  silla: Silla | undefined
  teamName: string
  gameDate: string | undefined
}

const SILLAS = [
  {
    id: '1',
    code: '0555550165503230455557',
    name: 'Roiner Camacho Esquivel',
    bloque: 'G',
    fila: 'I',
    asiento: '2',
  },
  {
    id: '2',
    code: '0000001470018682300002',
    name: 'Paola Vargas Castro',
    bloque: 'G',
    fila: 'I',
    asiento: '1',
  },
  {
    id: '3',
    code: '0000005198035672700003',
    name: 'Andrés Marenco Zúñiga',
    bloque: 'I',
    fila: 'L',
    asiento: '12',
  },
  {
    id: '4',
    code: '0000005198035672100003',
    name: 'Tomas Soley Rojas',
    bloque: 'I',
    fila: 'L',
    asiento: '13',
  },
  {
    id: '5',
    code: '0000005198035672300003',
    name: 'Roger Soley Brenes',
    bloque: 'I',
    fila: 'L',
    asiento: '14',
  },
]

export const useTicket = (): Values => {
  let { userId, teamName, gameDate } = useParams()

  // @ts-ignore
  const silla = SILLAS.find((element) => element.id === userId)

  const transformedTeamName = teamName?.toUpperCase() || ''

  return {
    silla,
    teamName: transformedTeamName,
    gameDate,
  }
}
