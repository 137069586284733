import {useEffect, useState} from "react";

import {useNavigate} from "react-router-dom";
import {Grid, Typography} from "@mui/material";
import styled from "@emotion/styled";

import {TagItem} from "./TagItem";
import {ImageViewer} from "./ImageViewer";

import {Workshop} from "../types";
import {Trim} from "../utils/trim";

// @ts-ignore
import spain from '../assets/spain.png'
// @ts-ignore
import usa from '../assets/usa.png'
import {LANGUAGE_SPANISH} from "../constant";

interface Props {
    workshop: Workshop
}

export const WorkshopCard = ({workshop}: Props) => {
    const navigate = useNavigate()
    const [laguageImage, setLanguageImage] = useState<any>()

    const {id, title, description, tags, language} = workshop

    useEffect(() => {
        const imageToUse = language === LANGUAGE_SPANISH ? spain : usa
        setLanguageImage(imageToUse)
    }, [language])

    const onClick = () => {
        navigate(`/workshops/${id}`)
    }

    return (
        <Grid item sm={6} onClick={onClick}>
            <Content>
                <Grid container>
                    <Grid
                        item
                        display={"flex"}
                        flexDirection={"column"}
                        justifyContent={"center"}
                    >
                        <Title variant="h4" color={'primary.main'} className={'title'}>{title}</Title>
                        <DescriptionContainer container>
                            <Description variant="body1" className={'description'}>{Trim({ text: description })}</Description>
                        </DescriptionContainer>
                    </Grid>
                </Grid>
                <DetailsSectionContainer container className={'opacity-manager'}>
                    <Grid
                        container
                        justifyContent={'flex-end'}
                        alignItems={"center"}
                    >
                        {/*<StyledLanguage variant='body2'>{lang}</StyledLanguage>*/}
                        <ImageViewer
                            // display={{ xs: 'none', sm: 'flex'}}
                            src={laguageImage}
                            size={'25px'}
                        />
                    </Grid>
                    <TagsContainer container>
                        {tags.map((tag) => {
                            return <TagItem key={tag} id={tag}/>
                        })}
                    </TagsContainer>
                </DetailsSectionContainer>
            </Content>
        </Grid>
    )
}

const Content = styled(Grid)`
  margin: 10px;
  padding: 10px;

  border: 1px solid #A4A5A6;
  border-radius: 10px;
  opacity: 0.8;

  &:hover {
    cursor: pointer;
    opacity: 1;

    .title {
      color: ${({theme}) => theme.palette.primary.dark};
    }

    .description {
      color: ${({theme}) => theme.palette.text.primary};
    }

    .opacity-manager {
      //opacity: 1;
    }
  }
`

const Title = styled(Typography)`
  margin-bottom: 10px;
  font-family: 'Lato', sans-serif;
  font-weight: 900;
`

const DescriptionContainer = styled(Grid)`
  height: 100px;
`

const Description = styled(Typography)`
  color: ${({theme}) => theme.palette.text.secondary};
  font-family: 'Lato', sans-serif;
`

const DetailsSectionContainer = styled(Grid)`
  margin-top: 5px;
  padding-top: 5px;
  flex-wrap: nowrap;
  flex-direction: column;
  border-top: 1px solid #A4A5A6;
`

const TagsContainer = styled(Grid)`
`
