import React, { useState } from 'react'

import styled from '@emotion/styled'
import { Grid, Button, Menu, MenuItem } from '@mui/material'

import { useMenu } from './useMenu'
import { theme } from '../../styles/theme'

export const MenuMobile = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const { onClickMyPosts, onClickAboutMe, onClickToPlay } = useMenu()

  const handleOnClickMyPosts = () => {
    handleClose()
    onClickMyPosts()
  }

  const handleonClickToPlay = () => {
    handleClose()
    onClickToPlay()
  }

  const handleOnClickAboutMe = () => {
    handleClose()
    onClickAboutMe()
  }

  return (
    <MenuContainer container padding={{ xs: '15px 40px' }} display={{ xs: 'flex', sm: 'none' }}>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        style={{
          backgroundColor: theme.palette.primary.dark,
        }}
      >
        Menu
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleOnClickMyPosts}>home</MenuItem>
        <MenuItem onClick={handleonClickToPlay}>to play</MenuItem>
        <MenuItem onClick={handleOnClickAboutMe}>about me</MenuItem>
      </Menu>
    </MenuContainer>
  )
}

const MenuContainer = styled(Grid)`
  background-color: #e0a80d;
  padding: 30px 0;
  //margin-bottom: 30px;
  box-shadow: 0 4px 20px 0 #856717;
`
