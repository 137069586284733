import {Box, Typography} from "@mui/material";
import styled from "@emotion/styled";
import {getTagInfoById} from "../utils/getTagInfoById";

interface Props {
    id: number
}

export const TagItem = ({id}: Props) => {
    const selectedTag = getTagInfoById(id)

    if (!selectedTag) return <></>

    const { color, label } = selectedTag

    return (
        <TagContainer color={color}>
            <StyledLabel variant="caption">{label}</StyledLabel>
        </TagContainer>
    )
}

type TagProps = {
    color: string
}

const TagContainer = styled(Box)<TagProps>(
    props => ({
        // color: props.color,
        display: 'flex',
        marginRight: '10px',
        border: `1px solid ${props.color}`,
        borderRadius: '13px',
        padding: '0 10px',
        backgroundColor: props.color,
        opacity: 0.8
    })
)

const StyledLabel = styled(Typography)`
  color: #ffffff
`