import styled from '@emotion/styled'
import { Grid, Typography } from '@mui/material'
import { BACKGROUND_DARK_COLOR, BACKGROUND_LIGHT_COLOR } from '../constants'
import { Title } from './Title'

interface Props {
  pair?: boolean
  title?: string
  subtitle?: string
  children: JSX.Element
}

export const Content = ({ pair = false, title, subtitle, children }: Props) => {
  return (
    <Container container pair={pair}>
      <Grid item sm={3} display={{ xs: 'none', sm: 'flex' }}>
        <Title pair={pair} title={title} subtitle={subtitle} />
      </Grid>
      <TitleContainer item xs={12} display={{ xs: 'flex', sm: 'none' }}>
        <StyledTitle>{title}</StyledTitle>
      </TitleContainer>
      <Grid item sm={9}>
        <Grid container maxWidth="900px" margin="auto">
          <Grid item xs={12}>
            {children}
          </Grid>
        </Grid>
      </Grid>
    </Container>
  )
}

interface ContainerProps {
  pair: boolean
}

const Container = styled(Grid)`
  display: flex;
  background-color: ${({ pair }: ContainerProps) => (pair ? BACKGROUND_DARK_COLOR : BACKGROUND_LIGHT_COLOR)};
`

const TitleContainer = styled(Grid)`
  display: flex;
  justify-content: center;
  padding: 30px 0 0;
`

const StyledTitle = styled(Typography)`
  color: ${({ theme }) => theme.palette.primary.main};
  font-weight: 600;
`
