import React, { Fragment } from 'react'

import { connect } from 'react-redux'
import styled from '@emotion/styled'
import { Grid } from '@mui/material'

import { SocialNetwork } from '../../components'

import {
  About,
  Contact,
  Education,
  Experience,
  Expertise,
  Languages,
  PersonalPortfolio,
  Presentation,
  ProfessionalPortfolio,
  Profiles,
  Skills,
} from './sections'

interface Props {
  dispatch: Function
}

const Home = ({ dispatch }: Props) => {
  return (
    <Fragment>
      <HomeContainer container>
        <Presentation pair />
        <About />
        <Languages pair />
        <Expertise />
        <Skills pair />
        <Experience />
        <ProfessionalPortfolio pair />
        <PersonalPortfolio />
        <Education pair />
        <Profiles />
        <Contact pair />
      </HomeContainer>
      <SocialNetwork />
    </Fragment>
  )
}

const HomeContainer = styled(Grid)``

export default connect()(Home)
