import {
    ADD_POSTS,
    ADD_ARTICLES,
    ADD_LIBRARIES,
    ADD_TOOLS,
    ADD_WORKSHOPS,
    CHANGE_FILTER_LANGUAGE,
    CHANGE_LANGUAGE,
    CHANGE_ROUTE,
    CHANGE_FILTER_TAG
} from './actions/actions'
import {Action} from "../types";
import {LANGUAGE_ALL} from "../constant";

const initialState = {
    language: LANGUAGE_ALL,
    route: '/',
    posts: [],
    articles: [],
    libraries: [],
    tools: [],
    workshops: [],
    filters: {
        languages: [],
        tags: []
    }
}

const reducer = (state = initialState, action: Action) => {

    const {filters: {languages, tags}} = state

    switch (action.type) {
        case ADD_POSTS:
            return {...state, posts: action.payload}
        case ADD_ARTICLES:
            return {...state, articles: action.payload}
        case ADD_LIBRARIES:
            return {...state, libraries: action.payload}
        case ADD_TOOLS:
            return {...state, tools: action.payload}
        case ADD_WORKSHOPS:
            return {...state, workshops: action.payload}
        case CHANGE_LANGUAGE:
            return {...state, language: action.payload}
        case CHANGE_ROUTE:
            return {...state, route: action.payload}
        case CHANGE_FILTER_LANGUAGE:

            let languagesToUse: string[] = []

            // @ts-ignore
            const languageIndex = languages.indexOf(action.payload)
            if (languageIndex > -1) {
                languages.splice(languageIndex, 1)
                languagesToUse = languages
            } else {
                languagesToUse = [...languages, action.payload]
            }

            return {...state, filters: {...state.filters, languages: [...languagesToUse]}}

        case CHANGE_FILTER_TAG:

            let tagsToUse: string[] = []

            // @ts-ignore
            const tagIndex = tags.indexOf(action.payload)
            if (tagIndex > -1) {
                tags.splice(tagIndex, 1)
                tagsToUse = tags
            } else {
                tagsToUse = [...tags, action.payload]
            }

            return {...state, filters: {...state.filters, tags: [...tagsToUse]}}

        default:
            return state
    }
}

export default reducer
