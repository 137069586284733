import styled from '@emotion/styled'
import { Grid, Typography } from '@mui/material'
import { ChronologicalInformation, Content, OnlineCourseInformation } from '../components'

interface Props {
  pair?: boolean
}

const TITLE = 'EDUCATION'
const SUBTITLE = `well, I like to learn`

const DATA = [
  {
    id: 2,
    initialDate: 'February 2013',
    finalDate: 'December 2013',
    organization: 'Conversational English',
    title: 'Wizard Institude',
    country: 'San José, Costa Rica',
    details: [
      {
        id: 1,
        text: `I am a native Spanish speaker, I signed up for this conversational English course to improve
                    communication skills, on top of that I have been involved in multilingual projects to continue
                    learning and improving my second language.`,
      },
    ],
  },
  {
    id: 1,
    initialDate: 'January 2008',
    finalDate: 'December 2012',
    organization: 'Bachillerato en Informática Empresarial',
    title: 'Universidad de Costa Rica',
    country: 'Alajuela, Costa Rica',
    details: [
      {
        id: 1,
        text: `I am a graduate of one of the most prestigious universities in Latin America,
                I was at the University of Costa Rica learning about business, technology and software development.
                I learned all the skills required to start my professional career, venturing into different companies
                always related to software.`,
      },
    ],
  },
]

const DATA2 = [
  {
    id: '01',
    date: '2018',
    title: 'Modern React with Redux',
    author: 'Udemy',
  },
  {
    id: '02',
    date: '2019',
    title: 'React Hooks',
    author: 'Udemy',
  },
  {
    id: '03',
    date: '2020',
    title: 'Vue.js',
    author: 'Udemy',
  },
  {
    id: '04',
    date: '2020',
    title: 'JavaScript - Advanced concepts',
    author: 'Zero to Master Academy',
  },
  {
    id: '05',
    date: '2021',
    title: 'Epic React',
    author: 'By Kent C. Dodds',
  },
  {
    id: '06',
    date: '2022',
    title: 'Python',
    author: 'Udemy',
  },
  {
    id: '07',
    date: '2022',
    title: 'Next.js',
    author: 'Vercel',
  },
  {
    id: '08',
    date: '2023',
    title: 'Total TypeScript',
    author: 'Matt Pocock',
  },
]

export const Education = ({ pair = false }: Props) => {
  return (
    <Content pair={pair} title={TITLE} subtitle={SUBTITLE}>
      <SectionContent container direction={{ xs: 'column', sm: 'row' }} padding={{ xs: '25px', sm: '50px' }}>
        {DATA.map(({ id, initialDate, finalDate, organization, title, country, details }) => (
          <ChronologicalInformation
            key={id}
            initialDate={initialDate}
            finalDate={finalDate}
            organization={organization}
            title={title}
            country={country}
            details={details}
          />
        ))}
        <SubTitleContainer item sm={12}>
          <SubTitle variant="body1">ONLINE COURSES</SubTitle>
        </SubTitleContainer>
        {DATA2.slice(0)
          .reverse()
          .map(({ id, date, title, author }) => (
            <OnlineCourseInformation key={id} id={id} date={date} title={title} author={author} />
          ))}
      </SectionContent>
    </Content>
  )
}

const SectionContent = styled(Grid)`
  justify-content: space-between;
`

const SubTitleContainer = styled(Grid)`
  padding: 20px 0;
`

const SubTitle = styled(Typography)`
  color: #e0a80d;
  font-weight: 600;
`
