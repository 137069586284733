// @ts-ignore
import patternsDocument from '../data/workshops/documents/reactPatterns/react_patterns.md'
// @ts-ignore
import notFound from '../data/workshops/documents/not_found.md'

export const getWorkshopById =  (id: string | undefined): any => {
    switch(id) {
        case '1':
            return patternsDocument
        default:
            return notFound
    }
}