import {ADD_LIBRARIES} from "./actions";
import {Library} from "../../types";

interface Props {
    libraries: Library[]
}

export const addLibraries = ({ libraries }: Props) => {
    return {
        type: ADD_LIBRARIES,
        payload: libraries
    }
}