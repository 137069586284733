import styled from '@emotion/styled'

import { Grid, Typography } from '@mui/material'

export const OnlineCourseInformation = ({ id, date, title, author }) => {
  return (
    <Content key={id} item columnSpacing={2} sm={6}>
      <Grid item display="flex">
        <IdLabel variant="body1">{id}</IdLabel>
        <NameLabel variant="body1">{title}</NameLabel>
      </Grid>
      <Grid item>
        <DescriptionLabel variant="body1">
          {date} - {author}
        </DescriptionLabel>
      </Grid>
    </Content>
  )
}

const Content = styled(Grid)`
  display: flex;
  padding: 0 20px;
  margin-bottom: 40px;
  flex-direction: column;
`

const IdLabel = styled(Typography)`
  color: #e0a80d;
  font-weight: 600;
`

const NameLabel = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.secondary};
  font-weight: 600;
  text-transform: uppercase;
  margin-left: 10px;
`

const DescriptionLabel = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.secondary};
  font-weight: 400;
  margin-left: 27px;
`
