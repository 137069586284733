import styled from '@emotion/styled'

import { Grid, Typography, Box } from '@mui/material'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const ProfileBox = ({ name, description, icon, url }) => {
  const onClick = () => {
    window.open(url, '_blank')
  }

  return (
    <Content item columnSpacing={2} sm={6} onClick={onClick}>
      <NameContainer item>
        {icon && (
          <ImageContainer>
            <StyledFontAwesomeIcon icon={icon} className={'fa-xl'} />
          </ImageContainer>
        )}
        <StyledName variant="body1">{name}</StyledName>
      </NameContainer>
      <Grid item>
        <StyledDescription paddingRight={{ xs: '0', sm: '50px' }}>{description}</StyledDescription>
      </Grid>
    </Content>
  )
}

const Content = styled(Grid)`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  padding-bottom: 20px;
`

const NameContainer = styled(Grid)`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
`

const ImageContainer = styled(Box)`
  height: 24px;
  width: 32px;
`

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.palette.text.secondary};
`

const StyledName = styled(Typography)`
  text-transform: uppercase;
  margin-left: 5px;
  font-weight: 600;
  color: ${({ theme }) => theme.palette.primary.main};
`

const StyledDescription = styled(Typography)`
  font-weight: 400;
  color: ${({ theme }) => theme.palette.text.secondary};
  margin-left: 37px;
`
