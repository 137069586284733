import styled from '@emotion/styled'
import { Grid } from '@mui/material'
import { Content, SkillBox } from '../components'

interface Props {
  pair?: boolean
}

const TITLE = 'LAGUAGES'
const SUBTITLE = `I've been talking with people from many countries`

const DATA = [
  {
    id: 1,
    name: 'Spanish (Native)',
    progress: 100,
  },
  {
    id: 2,
    name: 'English',
    progress: 85,
  },
]

export const Languages = ({ pair = false }: Props) => {
  return (
    <Content pair={pair} title={TITLE} subtitle={SUBTITLE}>
      <SectionContent container padding={{ xs: '25px', sm: '50px' }}>
        {DATA.map(({ id, name, progress }) => (
          <SkillBox key={id} name={name} progress={progress} />
        ))}
      </SectionContent>
    </Content>
  )
}

const SectionContent = styled(Grid)`
  justify-content: space-around;
`
