import {getPosts} from "../services";
import {useSelector} from "react-redux";
import {addPosts} from "../store/actions";
import {useEffect} from "react";
import {filterByLanguages} from "../utils/filterByLanguages";
import {filterByTags} from "../utils/filterByTags";

interface Props {
    dispatch: Function
}

export const useGetPosts = ({dispatch}: Props) => {
    let postsToDisplay = []
    const {posts} = getPosts()

    // @ts-ignore
    const filteredLanguages = useSelector((state) => state.filters.languages)
    // @ts-ignore
    const filteredTags = useSelector((state) => state.filters.tags)

    useEffect(() => {
        postsToDisplay = filterByLanguages({languages: filteredLanguages, items: posts})
        postsToDisplay = filterByTags({tags:filteredTags, items: postsToDisplay})
        dispatch(addPosts({posts: postsToDisplay}))
    }, [filteredLanguages, filteredTags])
}