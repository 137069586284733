import {Library} from "../types";
import data from "../data/home/home.json";

interface GetLibrariesValues {
    libraries: Library[]
}

export const getLibraries = (): GetLibrariesValues => {
    const libraries = data.libraries

    return {
        libraries
    }
}