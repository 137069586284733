import styled from '@emotion/styled'
import { Grid, Typography } from '@mui/material'
import { Content } from '../components'

interface Props {
  pair?: boolean
}

const TITLE = 'ABOUT ME'
const SUBTITLE = `who I'm?`

const DATA = [
  {
    content: `I'm an outgoing person with a passion for sports and wellness, and I truly enjoy connecting with new people.`,
  },
  {
    content: `As a professional software engineer, I love using my headphones, my keyboard and
    spending a couple of hours coding,  I'm deeply committed to my work, driven by the belief in making a real impact and enhancing the lives of others.`,
  },
  {
    content: `I have a strong passion for continuous learning and staying updated with the latest technology trends. Reading about new tech advancements
     excites me, and I love to dive into coding sessions to experiment with and apply what I've learned, turning theory into practice with enthusiasm.`,
  },
]

export const About = ({ pair = false }: Props) => {
  return (
    <Content pair={pair} title={TITLE} subtitle={SUBTITLE}>
      <SectionContent padding={{ xs: '25px', sm: '50px' }}>
        {DATA.map((info, index) => (
          <StyledContent variant="body1" key={index}>
            {info.content}
          </StyledContent>
        ))}
      </SectionContent>
    </Content>
  )
}

const SectionContent = styled(Grid)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 50px;
`

const StyledContent = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.secondary};
  font-weight: 400;
  padding-bottom: 10px;
`
