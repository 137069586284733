import {CHANGE_ROUTE} from "./actions";

interface Props {
    route: string
}

export const changeRoute = ({ route }: Props) => {
    return{
        type: CHANGE_ROUTE,
        payload : route,
    };
};