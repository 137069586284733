import {Post, Tag, Workshop} from "../types";

interface Props {
    tags: number[]
    items: Post[] | Workshop[]
}

export const filterByTags = ({tags, items}: Props): any[] => {

    return (
        tags.length ?
            items.filter(
                item => item.tags.some(
                    tag => tags.includes(tag)
                )
            ) :
            items
    )
}
