import React from 'react'

import styled from '@emotion/styled'

import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'

const DATE_FORMAT = 'DD/MM/YYYY HH:mm:ss'

export const DateTimeSelector = ({ onChange, value, name }) => {
  const handleOnChange = (newValue) => {
    const target = {
      value: newValue,
      name,
    }

    onChange({ target })
  }

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <StyledDateTimePicker
        label="Fecha y Hora"
        value={value}
        onChange={handleOnChange}
        ampm={false}
        closeOnSelect
        // minDate={moment()}
        format={DATE_FORMAT}
      />
    </LocalizationProvider>
  )
}

const StyledDateTimePicker = styled(MobileDateTimePicker)`
  width: 100%;
  margin-bottom: 20px;
`
