import {getLibraries} from "../services";
import {addLibraries} from "../store/actions";
import {useEffect} from "react";
import {useSelector} from "react-redux";
import {filterByLanguages} from "../utils/filterByLanguages";
import {filterByTags} from "../utils/filterByTags";

interface Props {
    dispatch: Function
}

export const useGetLibraries = ({dispatch}: Props) => {
    let librariesToDisplay = []
    const {libraries} = getLibraries()

    // @ts-ignore
    const filteredLanguages = useSelector((state) => state.filters.languages)
    // @ts-ignore
    const filteredTags = useSelector((state) => state.filters.tags)

    useEffect(() => {
        librariesToDisplay = filterByLanguages({languages: filteredLanguages, items: libraries})
        librariesToDisplay = filterByTags({tags:filteredTags, items: librariesToDisplay})
        dispatch(addLibraries({libraries: librariesToDisplay}))
    }, [filteredLanguages, filteredTags])
}