import { Box, Grid, Typography } from '@mui/material'

import { faSafari, faLinkedin, faMediumM, faInstagram, faDev, faGithub } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from '@emotion/styled'
import { ROUTE_MY_POSTS } from '../constant'

const SOCIAL_NETWORKS = [
  {
    id: 0,
    icon: faSafari,
    label: 'Go to my Playground',
    url: `${process.env.REACT_APP_BASE_URL}${ROUTE_MY_POSTS}`,
  },
  {
    id: 2,
    icon: faGithub,
    label: 'My Github Profile',
    url: 'https://github.com/rocaes90',
  },
  {
    id: 3,
    icon: faLinkedin,
    label: 'My LinkedIn Profile',
    url: 'https://www.linkedin.com/in/rocaes90/',
  },
  {
    id: 4,
    icon: faMediumM,
    label: 'My Medium Profile',
    url: 'https://medium.com/@rocaes90',
  },
  {
    id: 5,
    icon: faDev,
    label: 'My Dev Profile',
    url: 'https://dev.to/rocaes90',
  },
  {
    id: 6,
    icon: faInstagram,
    label: 'My Instagram Profile',
    url: 'https://www.instagram.com/rcamacho506/',
  },
  // {
  //   id: 7,
  //   icon: faTwitterSquare,
  //   label: 'My Twitter Profile',
  //   url: 'https://twitter.com/rocaes90',
  // },
]

export const SocialNetwork = () => {
  // @ts-ignore
  const onClick = ({ urlToNavigate }) => {
    window.open(urlToNavigate, '_blank')
  }

  return (
    <Container display={{ xs: 'none', sm: 'block' }} left={{ sm: '10px', xl: '50px' }}>
      {SOCIAL_NETWORKS.map(({ id, icon, label, url }) => {
        return (
          <Content key={id} container>
            <IconContainer onClick={() => onClick({ urlToNavigate: url })}>
              <FontAwesomeIcon icon={icon} className={'fa-2xl'} />
            </IconContainer>
            <NameContainer>
              <Description variant="body2">{label}</Description>
            </NameContainer>
          </Content>
        )
      })}
    </Container>
  )
}

const Container = styled(Box)`
  //width: 50px;
  position: fixed;
  bottom: 50px;
  display: flex;
  flex-direction: column;
`

const Content = styled(Grid)`
  width: auto;
  margin-bottom: 20px;
  align-items: center;
`

const IconContainer = styled(Box)`
  position: relative;
  display: flex;
  color: ${({ theme }) => theme.palette.text.secondary};
  width: 50px;

  &:hover {
    cursor: pointer;
    color: ${({ theme }) => theme.palette.primary.main};
  }
  &:hover + div {
    max-width: 100%;
  }
`

const NameContainer = styled(Box)`
  width: auto;
  max-width: 0;
  white-space: nowrap;
  overflow: hidden;
  transition: max-width 0.5s linear;
`

const Description = styled(Typography)`
  color: #e0a80d !important;
`
