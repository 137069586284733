import styled from "@emotion/styled";
import {Button, Grid, Typography} from "@mui/material";
import {connect} from "react-redux";

import {useFilter} from "./useFilter";
import {theme} from "../../styles/theme";

interface Props {
    dispatch: Function
}

const Filter = ({dispatch}: Props) => {

    const {
        handleOnClickSpanish,
        handleOnClickEnglish,
        handleOnClickTag,
        spanishButtonProps,
        englishButtonProps,
        tagsToDisplay,
        filteredTags
    } = useFilter({dispatch})

    console.log('tagsToDisplay', tagsToDisplay)

    return (
        <FilterContainer container alignItems={'center'}>
            <LanguagesContainer
                item
                xs={12}
                md={3}
                lg={2}
                flexDirection={{ xs: "column", md: "row" }}
            >
                <StyledPhrase variant="body2">what language do you prefer?</StyledPhrase>
                <StyledButton
                    style={{...englishButtonProps}}
                    variant="outlined"
                    onClick={() => handleOnClickEnglish()}>english</StyledButton>
                <StyledButton
                    style={{...spanishButtonProps}}
                    variant="outlined"
                    onClick={() => handleOnClickSpanish()}>spanish</StyledButton>
            </LanguagesContainer>
            <TagsContainer
                item
                xs={12}
                md={9}
                lg={10}
            >
                <StyledPhrase variant="body2">what topic do you want?</StyledPhrase>
                {
                    tagsToDisplay.map(tag => {
                        return (
                            <StyledButton
                                key={tag.id}
                                style={filteredTags.includes(tag.id) ? {
                                    backgroundColor: theme.palette.primary.dark,
                                    color: '#FFF'
                                } : {}}
                                variant="outlined"
                                onClick={() => handleOnClickTag(tag)}>{tag.label}</StyledButton>
                        )
                    })
                }
            </TagsContainer>
        </FilterContainer>
    )
}

const FilterContainer = styled(Grid)`
  background-color: #ffffff;
  box-shadow: 0 4px 20px 0 #cccccc;
  padding: 5px 10px;
  align-items: flex-start;
`

const LanguagesContainer = styled(Grid)`
  //display: flex;
  align-items: flex-start;
  //flex-direction: row;
  padding-right: 20px;
  //border-right: 1px solid red;
`


const TagsContainer = styled(Grid)`
  align-items: flex-start;
  //display: flex;
  //align-items: center;
  //flex-direction: row;
  //border-right: 1px solid red;
`

const StyledPhrase = styled(Typography)`
  padding-left: 10px;
  margin-bottom: 10px;
  color: ${({theme}) => theme.palette.primary.dark};
  font-family: 'Lato', sans-serif;
  margin-right: 10px;
`

const StyledButton = styled(Button)`
  color: ${({theme}) => theme.palette.primary.dark};
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  padding: 0 5px;
  text-transform: lowercase;;
  margin: 5px 5px;

  &:hover {
    border-color: ${({theme}) => theme.palette.primary.light};
  }
`

export default connect()(Filter)