import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { ROUTE_MY_POSTS } from '../../constant'
import { changeRoute } from '../../store/actions'
import { Library, Post, Article } from '../../types'
import { useGetArticles, useGetLibraries, useGetPosts, useGetTools } from '../../hooks'

interface Values {
  posts: Post[]
  articles: Article[]
  libraries: Library[]
  tools: any[]
}

interface Props {
  dispatch: Function
}

export const useMyPosts = ({ dispatch }: Props): Values => {
  useEffect(() => {
    dispatch(changeRoute({ route: ROUTE_MY_POSTS }))
  }, [dispatch])

  useGetPosts({ dispatch })
  useGetArticles({ dispatch })
  useGetLibraries({ dispatch })
  useGetTools({ dispatch })

  // @ts-ignore
  const posts = useSelector((state) => state.posts)
  // @ts-ignore
  const articles = useSelector((state) => state.articles)
  // @ts-ignore
  const libraries = useSelector((state) => state.libraries)
  // @ts-ignore
  const tools = useSelector((state) => state.tools)

  return {
    posts,
    articles,
    libraries,
    tools,
  }
}
