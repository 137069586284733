import { Grid } from '@mui/material'
import styled from '@emotion/styled'

import Menu from './Menu'
import Filter from './Filter'
import { MenuMobile } from './Menu/MenuMobile'

interface Props {
  displayFilter?: boolean
  children: JSX.Element
}

export const Container = ({ displayFilter = true, children }: Props) => {
  return (
    <ViewContainer container>
      <Menu />
      <MenuMobile />
      {displayFilter && <Filter />}
      <Content container padding={{ xs: '20px', sm: '20px 40px' }}>
        {children}
      </Content>
    </ViewContainer>
  )
}

const ViewContainer = styled(Grid)`
  flex-direction: column;
`

const Content = styled(Grid)`
  width: 100%;
  max-width: 1300px;
  margin: auto;
`
