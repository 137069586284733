import styled from '@emotion/styled'
import { Grid } from '@mui/material'
import { Content, ProfileBox } from '../components'
import { faFutbol } from '@fortawesome/free-solid-svg-icons'

interface Props {
  pair?: boolean
}

const TITLE = 'PERSONAL PORTFOLIO'
const SUBTITLE = `I like to study and learn new technologies while coding`

const DATA = [
  {
    id: 1,
    name: 'quiniela cr',
    description:
      "This is a personal application/game, basically It's a mobile app, where users can decide what the game is to bet who is the winner in the soccer games of a specific championship.",
    url: 'https://www.quinielacr.com/',
    icon: faFutbol,
  },
]

export const PersonalPortfolio = ({ pair = false }: Props) => {
  return (
    <Content pair={pair} title={TITLE} subtitle={SUBTITLE}>
      <SectionContent container direction={{ xs: 'column', sm: 'row' }} padding={{ xs: '25px', sm: '50px' }}>
        {DATA.map(({ id, name, icon, description, url }) => (
          <ProfileBox key={id} name={name} description={description} url={url} icon={icon} />
        ))}
      </SectionContent>
    </Content>
  )
}

const SectionContent = styled(Grid)`
  justify-content: space-between;
`
