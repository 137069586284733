import {Post} from "../types";
import data from "../data/home/home.json";

interface GetPostsValues {
    posts: Post[]
}

export const getPosts = (): GetPostsValues => {
    const posts = data.my_posts

    return {
        posts
    }
}