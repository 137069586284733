import {getWorkshops} from "../services";
import {addWorkshops} from "../store/actions";
import {useEffect} from "react";
import {useSelector} from "react-redux";
import {filterByLanguages} from "../utils/filterByLanguages";
import {filterByTags} from "../utils/filterByTags";

interface Props {
    dispatch: Function
}

export const useGetWorkshops = ({dispatch}: Props) => {
    let workshopsToDisplay = []
    const {workshops} = getWorkshops()

    // @ts-ignore
    const filteredLanguages = useSelector((state) => state.filters.languages)
// @ts-ignore
    const filteredTags = useSelector((state) => state.filters.tags)

    useEffect(() => {
        workshopsToDisplay = filterByLanguages({languages: filteredLanguages, items: workshops})
        workshopsToDisplay = filterByTags({tags: filteredTags, items: workshopsToDisplay})

        dispatch(addWorkshops({workshops: workshopsToDisplay}))

    }, [filteredLanguages, filteredTags])
}