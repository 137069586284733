export const ADD_POSTS = 'ADD_POSTS'
export const ADD_ARTICLES = 'ADD_ARTICLES'
export const ADD_LIBRARIES = 'ADD_LIBRARIES'
export const ADD_TOOLS = 'ADD_TOOLS'
export const ADD_WORKSHOPS = 'ADD_WORKSHOPS'
export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE'
export const CHANGE_ROUTE = 'CHANGE_ROUTE'
export const CHANGE_FILTER_LANGUAGE = 'CHANGE_FILTER_LANGUAGE'
export const CHANGE_FILTER_TAG = 'CHANGE_FILTER_TAG'

