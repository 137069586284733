import styled from '@emotion/styled'
import { Grid } from '@mui/material'
import { Content, ProfileBox } from '../components'
import {
  faDev,
  faGithub,
  faInstagram,
  faLinkedin,
  faMediumM,
  faTwitterSquare,
} from '@fortawesome/free-brands-svg-icons'
import { ROUTE_MY_POSTS } from '../../../constant'
import { faLeaf } from '@fortawesome/free-solid-svg-icons'

interface Props {
  pair?: boolean
}

const TITLE = 'PROFILES'
const SUBTITLE = `busy as always, this is what I like`

const DATA = [
  {
    id: 1,
    name: 'My posts',
    description: 'My personal development playground',
    icon: faLeaf,
    url: `${process.env.REACT_APP_BASE_URL}${ROUTE_MY_POSTS}`,
  },
  {
    id: 2,
    name: 'Medium',
    description: 'Some blogs related to my experiences and ideas',
    icon: faMediumM,
    url: 'https://medium.com/@rocaes90',
  },
  {
    id: 3,
    name: 'Dev',
    description: 'Some dev thoughts that I share with the community',
    icon: faDev,
    url: 'https://dev.to/rocaes90',
  },
  {
    id: 4,
    name: 'LinkedIn',
    description: 'LinkedIn profile with all my professional experiences, studies and other details',
    icon: faLinkedin,
    url: 'https://www.linkedin.com/in/rocaes90/',
  },
  {
    id: 5,
    name: 'Instagram',
    description: 'My personal space',
    icon: faInstagram,
    url: 'https://www.instagram.com/rcamacho506/',
  },
  {
    id: 6,
    name: 'Twitter',
    description: 'Some personal reflections on different topics',
    icon: faTwitterSquare,
    url: 'https://twitter.com/rocaes90',
  },
  {
    id: 7,
    name: 'Github',
    description: 'My personal work space',
    icon: faGithub,
    url: 'https://github.com/rocaes90',
  },
]

export const Profiles = ({ pair = false }: Props) => {
  return (
    <Content pair={pair} title={TITLE} subtitle={SUBTITLE}>
      <SectionContent container direction={{ xs: 'column', sm: 'row' }} padding={{ xs: '25px', sm: '50px' }}>
        {DATA.map(({ id, name, description, icon, url }) => (
          <ProfileBox key={id} name={name} description={description} icon={icon} url={url} />
        ))}
      </SectionContent>
    </Content>
  )
}

const SectionContent = styled(Grid)`
  justify-content: space-between;
`
