import {getTools} from "../services";
import {addTools} from "../store/actions";
import {useEffect} from "react";
import {useSelector} from "react-redux";
import {filterByLanguages} from "../utils/filterByLanguages";
import {filterByTags} from "../utils/filterByTags";

interface Props {
    dispatch: Function
}

export const useGetTools = ({dispatch}: Props) => {
    let toolsToDisplay = []
    const {tools} = getTools()

    // @ts-ignore
    const filteredLanguages = useSelector((state) => state.filters.languages)
    // @ts-ignore
    const filteredTags = useSelector((state) => state.filters.tags)

    useEffect(() => {
        toolsToDisplay = filterByLanguages({languages: filteredLanguages, items: tools})
        toolsToDisplay = filterByTags({tags:filteredTags, items: toolsToDisplay})
        dispatch(addTools({tools: toolsToDisplay}))
    }, [filteredLanguages, filteredTags])
}