import styled from '@emotion/styled'

import styles from './SkillBox.module.css'
import { Grid, Typography, LinearProgress } from '@mui/material'

export const SkillBox = ({ name, progress }) => {
  return (
    <Content item columnSpacing={2} xs={12} sm={5}>
      <Grid container>
        <Grid item xs={12} display="flex">
          <ProgressLabel variant="body1">{progress}%</ProgressLabel>
          <StyledName variant="body1">{name}</StyledName>
        </Grid>
        <Grid item xs={12}>
          <LinearProgress
            classes={{
              colorPrimary: styles.linearProgress,
              barColorPrimary: styles.barColorPrimary,
            }}
            variant="determinate"
            value={progress}
          />
        </Grid>
      </Grid>
    </Content>
  )
}

const Content = styled(Grid)`
  padding: 0 20px;
  margin: 15px 0;
`

const StyledName = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.secondary};
  font-weight: 600;
  padding-bottom: 10px;
  text-transform: uppercase;
  margin-left: 10px;
`

const ProgressLabel = styled(Typography)`
  color: #e0a80d;
`
