import React from 'react'

import styled from '@emotion/styled'
import { Button, Grid, MenuItem, Select, TextField, Typography } from '@mui/material'

// @ts-ignore
import saprissa from '../../assets/graderiaMorada/correo-confirmacion-2.jpg'

import { ImageViewer } from '../../components/ImageViewer'
import { useGraderiaMorada } from './useGraderiaMorada'
// import { DateTimeSelector } from '../../components'

const USERS = [
  {
    id: 1,
    name: 'Roiner Camacho Esquivel',
  },
  {
    id: 2,
    name: 'Paola Vargas Castro',
  },
  {
    id: 3,
    name: 'Andrés Marenco Zúñiga',
  },
  {
    id: 4,
    name: 'Tomas Soley Rojas',
  },
  {
    id: 5,
    name: 'Roger Soley Brenes',
  },
]

const Index = () => {
  // @ts-ignore
  const {
    handleOnCancel,
    formValues,
    handleOnFormFieldChange,
    handleOnClickGoToTicket,
    code,
    handleOnChangeCode,
    enableForm,
  } = useGraderiaMorada()

  const { teamName, gameDate, userId } = formValues

  return (
    <Container container>
      {!enableForm && (
        <CodeContainer container>
          <Typography variant="body2" marginBottom="10px">
            Código
          </Typography>
          <TextField
            label="Código"
            onChange={handleOnChangeCode}
            value={code}
            name="code"
            variant="outlined"
            color="primary"
          />
          <CancelButton variant="contained" color="primary" onClick={handleOnCancel}>
            Cancelar
          </CancelButton>
        </CodeContainer>
      )}
      {enableForm && (
        <>
          <ImageViewer src={saprissa} size="75px" width="350px" />
          <FormContainer container xs={10}>
            <Typography variant="body2" marginBottom="10px">
              Equipo rival
            </Typography>
            <StyledFomField label="Equipo rival" onChange={handleOnFormFieldChange} value={teamName} name="teamName" />
            <Typography variant="body2" marginBottom="10px">
              Fecha y Hora del juego
            </Typography>
            <Typography variant="body2" marginBottom="10px" color="gray">
              Dom 20 ago 2023 3:00 PM
            </Typography>
            <StyledFomField label="Fecha y hora" onChange={handleOnFormFieldChange} value={gameDate} name="gameDate" />
            {/* <DateTimeSelector onChange={handleOnFormFieldChange} value={gameDate} name="gameDate" /> */}
            <Typography variant="body2" marginBottom="10px">
              Usuario
            </Typography>
            <StyledFomSelector value={userId} onChange={handleOnFormFieldChange} label="Usuario" name="userId">
              {USERS &&
                USERS.map((element) => {
                  const { id, name } = element
                  return (
                    <MenuItem key={id} value={id}>
                      {name}
                    </MenuItem>
                  )
                })}
            </StyledFomSelector>
            <ButtonsContainer container>
              <Button variant="contained" color="primary" onClick={handleOnCancel}>
                Cancelar
              </Button>
              <StyledButton variant="contained" color="primary" onClick={handleOnClickGoToTicket}>
                VER TICKET
              </StyledButton>
            </ButtonsContainer>
          </FormContainer>
        </>
      )}
    </Container>
  )
}

const CancelButton = styled(Button)`
  margin-top: 20px;
`

const Container = styled(Grid)`
  /* height: 100vh; */
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: auto;
  background: ${({ theme }) => theme.palette.background.default};
`

const CodeContainer = styled(Grid)`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  align-items: center;
`

const FormContainer = styled(Grid)`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  align-items: center;
`

const StyledFomField = styled(TextField)`
  width: 100%;
  margin-bottom: 20px;
`

const StyledFomSelector = styled(Select)`
  width: 100%;
  margin-bottom: 20px;

  .MuiSelect-select {
    flex-direction: row;
    display: flex;
    align-items: center;
  }
`

const StyledButton = styled(Button)`
  width: 100%;
  max-width: 200px;
  margin-left: 20px;
`

const ButtonsContainer = styled(Grid)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
`

export default Index
