import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { ROUTE_HOME } from '../../constant'

const INITIAL_STATE = {
  teamName: '',
  userId: '',
  gameDate: '',
}

export const useGraderiaMorada = () => {
  const navigate = useNavigate()

  const [code, setCode] = useState('')
  const [enableForm, setEnableForm] = useState(false)

  const [formValues, setFormValues] = useState({ ...INITIAL_STATE })

  useEffect(() => {
    if (code === 'tomas') {
      setEnableForm(true)
    }
  }, [code])

  const handleOnFormFieldChange = (e: any) => {
    let { name, value } = e.target
    setFormValues({
      ...formValues,
      [name]: value,
    })
  }

  const handleOnClickGoToTicket = (e: React.MouseEvent<HTMLElement>) => {
    const { userId, teamName, gameDate } = formValues
    if (userId && teamName && gameDate) {
      navigate(`/ticket/${formValues.userId}/${formValues.teamName}/${gameDate}`)
    }
  }

  const handleOnChangeCode = (e: any) => {
    setCode(e.target.value)
  }

  const handleOnCancel = () => {
    navigate(ROUTE_HOME)
  }

  return {
    code,
    enableForm,
    formValues,
    handleOnCancel,
    handleOnChangeCode,
    handleOnClickGoToTicket,
    handleOnFormFieldChange,
  }
}
