import {useEffect, useState} from 'react'

import {Grid, Typography} from "@mui/material";
import styled from "@emotion/styled";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {TagItem} from "./TagItem";

import {Post} from "../types";
import {Trim} from "../utils/trim";
import {getIconBySource} from "../utils/getIconBySource";
import {ImageViewer} from "./ImageViewer";

// @ts-ignore
import spain from '../assets/spain.png'
// @ts-ignore
import usa from '../assets/usa.png'
import {LANGUAGE_ENGLISH} from "../constant";

interface Props {
    post: Post
}

export const PostCard = ({post}: Props) => {
    const {title, description, image, url, tags, source, language} = post

    const [laguageImage, setLanguageImage] = useState<any>()

    const icon = getIconBySource(source)

    useEffect(() => {
        const imageToUse = language === LANGUAGE_ENGLISH ? usa : spain
        setLanguageImage(imageToUse)
    }, [language])

    const onClick = () => {
        window.open(url, '_blank')
    }

    return (
        <CardContainer container onClick={onClick}>
            <Grid container>
                <Grid
                    item
                    xs={8}
                    md={9}
                    display={"flex"}
                    flexDirection={"column"}
                    justifyContent={"center"}
                >
                    <Title variant="h5" color={'primary.main'} className={'title'}>{title}</Title>
                    <Description variant="body1" className={'description'}>{Trim({text: description})}</Description>
                </Grid>
                <PostImageContainer
                    item
                    xs={4}
                    md={3}
                    display={"flex"}
                    justifyContent={"flex-end"}
                    alignItems={"center"}
                    className={'opacity-manager'}
                >
                    <ImageViewer src={image} size={'120px'}/>
                </PostImageContainer>
            </Grid>
            <DetailsSectionContainer container className={'opacity-manager'}>
                <Grid
                    item
                    xs={9}
                >
                    <Grid container>

                        {tags.map((tag) => {
                            return <TagItem key={tag} id={tag}/>
                        })}
                    </Grid>
                </Grid>
                <Grid
                    item
                    xs={3}
                    justifyContent={'flex-end'}
                    alignItems={"center"}
                >
                    <Grid container justifyContent={"flex-end"}>
                        <FontAwesomeIcon icon={icon} className={'fa-lg'}/>
                        <LanguageImageContainer item>
                            <ImageViewer
                                src={laguageImage}
                                size={'25px'}
                            />
                        </LanguageImageContainer>
                    </Grid>
                </Grid>
            </DetailsSectionContainer>
        </CardContainer>
    )
}

const CardContainer = styled(Grid)`
  border-bottom: 1px solid #A4A5A6;
  padding: 20px 0;

  &:hover {
    cursor: pointer;

    .title {
      color: ${({theme}) => theme.palette.primary.dark};
    }

    .description {
      color: ${({theme}) => theme.palette.text.primary};
    }

    .opacity-manager {
      opacity: 1;
    }
  }
`

const Title = styled(Typography)`
  margin-bottom: 10px;
  font-family: 'Lato', sans-serif;
  font-weight: 900;
`

const Description = styled(Typography)`
  color: ${({theme}) => theme.palette.text.secondary};
  font-family: 'Lato', sans-serif;
`

const PostImageContainer = styled(Grid)`
  min-width: 120px;
  opacity: 0.7
`

const LanguageImageContainer = styled(Grid)`
  border-left: 1px solid #A4A5A6;
  padding-left: 10px;
  margin-left: 10px;
`

const DetailsSectionContainer = styled(Grid)`
  margin-top: 30px;
  opacity: 0.7;
  flex-wrap: nowrap;
`
