import React from 'react'

import styled from '@emotion/styled'
import { Button, Grid } from '@mui/material'
import ReactMarkdown from 'react-markdown'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'

import { useWorkshopViewer } from './useToPlayViewer'

import { Container, SocialNetwork } from '../../components'
import { connect } from 'react-redux'

interface Props {
  dispatch: Function
}

const WorkshopsViewer = ({ dispatch }: Props) => {
  const { markdownContent, handleOnClickBack } = useWorkshopViewer({ dispatch })

  return (
    <React.Fragment>
      <Container>
        <StyledGrid container>
          <Grid container>
            <Button variant="contained" onClick={() => handleOnClickBack()} startIcon={<ArrowBackIosIcon />}>
              back
            </Button>
          </Grid>
          <MarkdownContainer>
            <ReactMarkdown
              children={markdownContent}
              transformImageUri={(uri) => (uri.startsWith('http') ? uri : `${process.env.REACT_APP_BASE_URL}${uri}`)}
            />
          </MarkdownContainer>
        </StyledGrid>
      </Container>
      <SocialNetwork />
    </React.Fragment>
  )
}

const StyledGrid = styled(Grid)`
  height: 100%;
`

const MarkdownContainer = styled.div`
  display: block;
  width: 100%;
`

export default connect()(WorkshopsViewer)
