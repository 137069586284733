import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { getWorkshopById } from '../../utils/getWorkshopById'
import { ROUTE_TO_PLAY } from '../../constant'
import { changeRoute } from '../../store/actions'

interface Props {
  dispatch: Function
}

interface WorkshopViewer {
  markdownContent: string
  handleOnClickBack: Function
}

export const useWorkshopViewer = ({ dispatch }: Props): WorkshopViewer => {
  const navigate = useNavigate()
  const [markdownContent, setMarkdownContent] = useState<string>('')

  let { workshopId } = useParams()

  useEffect(() => {
    dispatch(changeRoute({ route: ROUTE_TO_PLAY }))
  }, [dispatch])

  useEffect(() => {
    const markdownDocument = getWorkshopById(workshopId)
    fetch(markdownDocument)
      .then((res) => res.text())
      .then((text) => setMarkdownContent(text))
  }, [workshopId])

  const handleOnClickBack = () => navigate(ROUTE_TO_PLAY)

  return {
    markdownContent,
    handleOnClickBack,
  }
}
