import {Tool} from "../types";
import data from "../data/home/home.json";

interface GetToolsValues {
    tools: Tool[]
}

export const getTools = (): GetToolsValues => {
    const tools = data.tools

    return {
        tools
    }
}