import styled from '@emotion/styled'
import { Grid, Typography } from '@mui/material'

interface Props {
  title?: string
  subtitle?: string
  pair?: boolean
}

const BACKGROUND_DARK_COLOR = '#2b2c2c'
const BACKGROUND_LIGHT_COLOR = '#2d2e2e'

export const Title = ({ title, subtitle, pair = false }: Props) => {
  return (
    <Container container pair={pair}>
      <TitleContent item xs={12}>
        {title && <StyledTitle>{title}</StyledTitle>}
        {subtitle && <StyledSubTitle variant="body2">{subtitle}</StyledSubTitle>}
      </TitleContent>
    </Container>
  )
}

interface ContainerProps {
  pair: boolean
}

const Container = styled(Grid)`
  display: flex;
  height: 100%;
  background-color: ${({ pair }: ContainerProps) => (pair ? BACKGROUND_LIGHT_COLOR : BACKGROUND_DARK_COLOR)};
  padding: 70px 40px 0 40px;
  flex-direction: column;
`

const TitleContent = styled(Grid)`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

const StyledTitle = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.secondary};
  text-transform: uppercase;
  font-weight: 600;
  text-align: end;
  max-width: 300px;
`

const StyledSubTitle = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.secondary};
  text-align: end;
  max-width: 300px;
`
