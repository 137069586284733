import React from 'react'

import {Grid} from "@mui/material";

import {ArticleItem} from "./ArticleItem";
import {TitleSection} from "./TitleSection";

import {Article} from "../types";
import {EmptyValue} from "./EmptyValue";

interface Props {
    title: string
    subtitle?: string
    articles: Article[]
}

export const ArticlesSection = (
    {
        title, subtitle, articles
    }: Props) => {
    return (
        <Grid container>
            <TitleSection
                title={title}
                subtitle={subtitle}
                titleOriginalSize='36px'
            />
            <Grid container>
                {
                    articles && articles.map(article => {
                        return (<ArticleItem key={article.id} article={article}/>)
                    })
                }
                {
                    !articles.length && <EmptyValue/>
                }
            </Grid>
        </Grid>
    )
}
