import React from 'react'

import styled from "@emotion/styled";
import {Grid} from "@mui/material";

import {ReferenceItem} from "./ReferenceItem";
import {TitleSection} from "./TitleSection";

import {Article, Library} from "../types";
import {EmptyValue} from "./EmptyValue";

interface Props {
    title: string
    subtitle?: string
    items: Article[] | Library[]
}

export const ReferencesSection = (
    {
        title, subtitle, items
    }: Props) => {
    return (
        <Container container>
            {
                <TitleSection
                    title={title}
                    subtitle={subtitle}
                    titleOriginalSize='20px'
                />
            }
            <Grid container>
                {
                    items && items.map(item => {
                        return (<ReferenceItem key={item.id} article={item}/>)
                    })
                }
                {
                    !items.length && <EmptyValue/>
                }
            </Grid>
        </Container>
    )
}

const Container = styled(Grid)`
  //border-bottom: 1px solid #A4A5A6;
  //padding: 5px 0;
`

