// it could be your App.tsx file or theme file that is included in your tsconfig.json
import { createTheme, Theme as MUITheme } from '@mui/material/styles'
import '@mui/styles'

// declare module '@mui/styles/defaultTheme' {
//     interface DefaultTheme extends Theme {}
// }

declare module '@emotion/react' {
  export interface Theme extends MUITheme {}
}

export const theme = createTheme({
  typography: {
    fontFamily: 'Helvetica Neue,serif, Lato, sans-serif',
  },
  palette: {
    divider: '#a4a5a6',
    primary: {
      light: '#eebf3e',
      main: '#e0a80d',
      dark: '#806307',
    },
    secondary: {
      light: '',
      main: '#404242',
      dark: '',
    },
    text: {
      primary: '#000000',
      secondary: '#a4a5a6',
    },
    background: {
      default: '#f3f3f3',
    },
  },
})
