import React from 'react'

import { PostCard } from './PostCard'
import { TitleSection } from './TitleSection'
import { Post } from '../types'
import { EmptyValue } from './EmptyValue'

interface Props {
  title: string
  subtitle: string
  posts: Post[]
}

export const PostsSection = ({ title, subtitle, posts }: Props) => {
  return (
    <React.Fragment>
      <TitleSection title={title} subtitle={subtitle} titleOriginalSize={'96px'} />
      {posts &&
        posts.map((post) => {
          return <PostCard key={post.id} post={post} />
        })}
      {!posts.length && <EmptyValue />}
    </React.Fragment>
  )
}
