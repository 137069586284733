import React from 'react'
import styled from '@emotion/styled'
import { Grid, Typography } from '@mui/material'

interface Props {
  title: string
  subtitle?: string
  titleOriginalSize: string
  alignRight?: boolean
}

export const TitleSection = ({ title, subtitle, titleOriginalSize, alignRight }: Props) => {
  return (
    <TitleContainer container justifyContent={alignRight ? 'flex-end' : 'flex-start'}>
      <Title variant="h1" fontSize={{ sm: '30px', md: titleOriginalSize }}>
        {title}
      </Title>
      {subtitle && <Subtitle variant="body1">{subtitle}</Subtitle>}
    </TitleContainer>
  )
}

const TitleContainer = styled(Grid)`
  align-items: end;
  margin-bottom: 5px;
  //justify-content: flex-end;
`

const Title = styled(Typography)`
  display: flex;
  font-family: 'Lato', sans-serif;
  font-weight: 900;
  color: ${({ theme }) => theme.palette.text.primary};
  width: 100%;
  line-height: 0.75;
  padding-bottom: 20px;
  //justify-content: flex-end;
`

const Subtitle = styled(Typography)`
  display: flex;
  font-family: 'Lato', sans-serif;
  color: ${({ theme }) => theme.palette.text.secondary};
  font-weight: 700;
  //justify-content: flex-end;
`
