import {useEffect} from "react";

import {changeRoute} from "../../store/actions";
import {ROUTE_ABOUT} from "../../constant";

interface Props {
    dispatch: Function
}

export const useAboutUs = ({ dispatch }: Props) => {
    useEffect(() => {
        dispatch(changeRoute({route:ROUTE_ABOUT}))
    }, [dispatch])

    const onClickWeb = () => window.open('https://www.roinercamacho.com', '_blank')

    return {
        onClickWeb
    }
}