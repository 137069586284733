import styled from '@emotion/styled'
import { Grid } from '@mui/material'
import { Content } from '../components'
import { ExpertiseBox } from '../components/ExpertiseBox'

interface Props {
  pair?: boolean
}

const TITLE = 'EXPERTISE'
const SUBTITLE = `maybe not an expert, but very confident`

const DATA = [
  {
    id: '01',
    name: 'React JS',
    description:
      'With over 9 years of React experience, I am dedicated to constant innovation and improvement in applying this versatile library to development projects.',
  },
  {
    id: '02',
    name: 'Hooks',
    description: `Proficient in React Hooks, I relish leveraging their potential to craft efficient and elegant functional components.`,
  },
  {
    id: '03',
    name: 'JavaScript',
    description: `With extensive experience in JavaScript, I navigate its contemporary significance to drive dynamic web development and innovation.`,
  },
  {
    id: '04',
    name: 'Typescript',
    description: `Skilled in TypeScript, I enhance JavaScript development with strong typing, ensuring more reliable and maintainable code.`,
  },
  {
    id: '05',
    name: 'Apollo',
    description: `Over the past four years, my experience with Apollo has been transformative, showcasing its incredible versatility in crafting efficient, data-driven applications.`,
  },
]

export const Expertise = ({ pair = false }: Props) => {
  return (
    <Content pair={pair} title={TITLE} subtitle={SUBTITLE}>
      <SectionContent container padding={{ xs: '25px', sm: '50px' }}>
        {DATA.map(({ id, name, description }) => (
          <ExpertiseBox key={id} id={id} name={name} description={description} />
        ))}
      </SectionContent>
    </Content>
  )
}

const SectionContent = styled(Grid)`
  justify-content: space-between;
`
