import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { changeRoute } from '../../store/actions'
import { ROUTE_TO_PLAY } from '../../constant'
import { Workshop } from '../../types'
import { useGetWorkshops } from '../../hooks'

interface Props {
  dispatch: Function
}

interface UseToPlay {
  toPlay: Workshop[]
}

export const useToPlay = ({ dispatch }: Props): UseToPlay => {
  useEffect(() => {
    dispatch(changeRoute({ route: ROUTE_TO_PLAY }))
  }, [dispatch])

  useGetWorkshops({ dispatch })

  // @ts-ignore
  const toPlay = useSelector((state) => state.workshops)

  return {
    toPlay,
  }
}
