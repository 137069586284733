import React from 'react'

import styled from '@emotion/styled'
import {Button, Grid, Typography} from '@mui/material';
import {connect} from "react-redux";

import {useAboutUs} from "./useAboutUs";
import {Container, SocialNetwork} from "../../components";

interface Props {
    dispatch: Function
}

const Index = ({ dispatch }: Props) => {
    const { onClickWeb } = useAboutUs({ dispatch })

    return (
        <React.Fragment>
            <Container displayFilter={false}>
                <StyledGrid
                    container
                    xs={10}
                    md={7}
                    lg={5}
                    padding={{xs: '20px', sm: '40px'}}
                    flexDirection={'column'}
                    justifyContent={'center'}
                    margin={'auto'}
                >
                    <Typography variant={'h4'} marginBottom={'30px'}>
                        {`I'm Roiner Camacho Esquivel`}
                    </Typography>
                    <Paragraph variant={'h6'}>
                        {`I'm a Front-End Developer located in Costa Rica, I have more than 10 years of experience and a serious passion for web-development`}
                    </Paragraph>
                    <Paragraph variant={'h6'}>
                        {`I love coding with React.js, always trying to learn using different and new technologies, I understand that exploring and experimenting is a great way to keep growing in this career.`}
                    </Paragraph>
                    <Typography variant={'h6'}>
                        {`Feel free to visit my personal web site`}
                    </Typography>
                    <StyledButton variant="text" onClick={onClickWeb}>www.roinercamacho.com</StyledButton>
                </StyledGrid>
            </Container>
            <SocialNetwork/>
        </React.Fragment>
    )
}

const StyledGrid = styled(Grid)`
  height: 100%;
`

const Paragraph = styled(Typography)`
  margin-bottom: 30px;
`

const StyledButton = styled(Button)`
  margin-top: 15px;
  font-size: 30px;
  color: ${({theme}) => theme.palette.primary.dark};
  text-transform: lowercase;
`


export default connect()(Index)
