import styled from "@emotion/styled";
import {Grid, Typography} from "@mui/material";

import {TagItem} from "./TagItem";

import {Article} from "../types";
import {Trim} from "../utils/trim";

interface Props {
    article: Article
}

export const ArticleItem = ({article}: Props) => {
    const {title, description, url, tags} = article

    const onClick = () => {
        window.open(url, '_blank')
    }

    return (
        <ArticleContainer container>
            <Grid
                container
                onClick={onClick}
                flexDirection={"column"}
                // alignItems={"flex-end"}
            >
                <Title variant="body1" className={'title'}>{title}</Title>
                <Description variant="body2" className={'description'}>{Trim({ text: description })}</Description>
            </Grid>
            <TagsContainer
                container
                className={'tags'}
                // flexDirection={'row-reverse'}
            >
                {tags.map((tag) => {
                    return <TagItem key={tag} id={tag}/>
                })}
            </TagsContainer>
        </ArticleContainer>
    )
}

const ArticleContainer = styled(Grid)`
  padding: 10px 0;
  
  &:hover {
    cursor: pointer;

    .title {
      color: ${({theme}) => theme.palette.primary.dark}
    }

    .description {
      color: ${({theme}) => theme.palette.text.primary}
    }

    .tags {
      opacity: 1;
    }
  }
`

const Title = styled(Typography)`
  margin-bottom: 5px;
  font-family: 'Lato', sans-serif;
  font-weight: 900;
  color: ${({theme}) => theme.palette.primary.main}
`


const Description = styled(Typography)`
  color: ${({theme}) => theme.palette.text.secondary};
  font-family: 'Lato', sans-serif;
`

const TagsContainer = styled(Grid)`
  margin-top: 10px;
  opacity: 0.7;
`
