import {ADD_POSTS} from "./actions";
import {Post} from "../../types";

interface Props {
    posts: Post[]
}

export const addPosts = ({ posts }: Props) => {
    return {
        type: ADD_POSTS,
        payload: posts
    }
}