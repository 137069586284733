import styled from '@emotion/styled'
import { Grid } from '@mui/material'
import { Content, SkillBox } from '../components'

interface Props {
  pair?: boolean
}

const TITLE = 'SKILLS'
const SUBTITLE = `so many options, these are my favorites`

const DATA = [
  {
    id: 1,
    name: 'React JS',
    progress: 90,
  },
  {
    id: 2,
    name: 'Hooks',
    progress: 90,
  },
  {
    id: 3,
    name: 'Git',
    progress: 90,
  },
  {
    id: 4,
    name: 'JavaScript',
    progress: 90,
  },
  {
    id: 5,
    name: 'Apollo',
    progress: 85,
  },
  {
    id: 5,
    name: 'Typescript',
    progress: 85,
  },
  {
    id: 6,
    name: 'Unit Testing',
    progress: 85,
  },
  {
    id: 7,
    name: 'GraphQL',
    progress: 80,
  },
  {
    id: 7,
    name: 'Material-UI',
    progress: 80,
  },
  {
    id: 8,
    name: 'Redux',
    progress: 80,
  },
  {
    id: 9,
    name: 'Python',
    progress: 50,
  },
  {
    id: 10,
    name: 'Django',
    progress: 50,
  },
]

export const Skills = ({ pair = false }: Props) => {
  return (
    <Content pair={pair} title={TITLE} subtitle={SUBTITLE}>
      <SectionContent container padding={{ xs: '25px', sm: '50px' }}>
        {DATA.map(({ id, name, progress }) => (
          <SkillBox key={id} name={name} progress={progress} />
        ))}
      </SectionContent>
    </Content>
  )
}

const SectionContent = styled(Grid)`
  justify-content: space-around;
`
