import {faMedium, faDev, IconDefinition, faSafari} from "@fortawesome/free-brands-svg-icons";

export const getIconBySource = (source: string): IconDefinition => {
    switch(source){
        case 'medium':
            return faMedium;
        case 'dev':
            return faDev;
        default:
            return faSafari;
    }
}