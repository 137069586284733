import styled from '@emotion/styled'
import { Box } from '@mui/material'

interface Props {
  src: string
  size: string
  width?: string
}

export const ImageViewer = ({ src, size, width }: Props) => {
  return <ImageContent src={src} size={size} widthsize={width || size} />
}

type ImageContentProps = {
  src: string
  size: string
  widthsize: string
}

const ImageContent = styled(Box)<ImageContentProps>((props) => ({
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  width: `${props.widthsize}`,
  height: `${props.size}`,
  backgroundImage: `url(${props.src})`,
}))
