import React from "react";
import {Grid, Typography} from "@mui/material";
import styled from "@emotion/styled";

interface Props {
    text?: string
}

export const EmptyValue = ({ text = 'Any value to display, try with different filters...'}: Props) => {
    return (
        <Grid container>
            <StyledEmptyLabel>{text}</StyledEmptyLabel>
        </Grid>
    )
}

const StyledEmptyLabel = styled(Typography)`
  margin: 20px auto;
  text-align: center;
  font-style: italic;
  color: ${({theme}) => theme.palette.text.secondary};
`