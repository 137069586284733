import React from 'react'

import { connect } from 'react-redux'
import styled from '@emotion/styled'
import { Grid } from '@mui/material'

import { Container, SocialNetwork, ArticlesSection, PostsSection, ReferencesSection } from '../../components'

import { useMyPosts } from './useMyPosts'

interface Props {
  dispatch: Function
}

const MyPosts = ({ dispatch }: Props) => {
  const { posts, articles, libraries, tools } = useMyPosts({ dispatch })

  return (
    <React.Fragment>
      <Container>
        <StyledGrid container>
          <Grid item sm={7}>
            <PostsSection
              title="my posts"
              subtitle={`well! I'm not a writer, but I've been writing something and I like to share my thoughts and some tech criteria`}
              posts={posts}
            />
          </Grid>
          <Grid item sm={5} paddingLeft={{ xs: '18px', sm: '24px', md: '100px' }}>
            <Grid container>
              <ArticlesSection title={'read articles'} subtitle={'some riveting articles'} articles={articles} />
            </Grid>
            <SecondarySectionContainer container>
              <ReferencesSection
                title={'implemented libraries'}
                subtitle={'I have been exploring some libraries '}
                items={libraries}
              />
            </SecondarySectionContainer>
            <SecondarySectionContainer container>
              <ReferencesSection
                title={'online tools'}
                subtitle={'these are some amazing web development tools i have found'}
                items={tools}
              />
            </SecondarySectionContainer>
          </Grid>
        </StyledGrid>
      </Container>
      <SocialNetwork />
    </React.Fragment>
  )
}

const StyledGrid = styled(Grid)`
  height: 100%;
`

const SecondarySectionContainer = styled(Grid)`
  margin-top: 25px;
`

export default connect()(MyPosts)
