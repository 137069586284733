import {LANGUAGE_ENGLISH, LANGUAGE_SPANISH} from "../../constant";
import {updateFilterLanguage} from "../../store/actions";
import {useSelector} from "react-redux";
import {theme} from "../../styles/theme";

import tags from "../../data/global/tags.json"
import {Tag} from "../../types";
import {updateFilterTag} from "../../store/actions/filters";

interface Props {
    dispatch: Function
}

interface UseFilter {
    handleOnClickSpanish: Function
    handleOnClickEnglish: Function
    handleOnClickTag: Function
    spanishButtonProps: object
    englishButtonProps: object
    tagsToDisplay: Tag[]
    filteredTags: number[]
}

export const useFilter = ({ dispatch }: Props): UseFilter => {

    // @ts-ignore
    const languages = useSelector((state) => state.filters.languages)

    // @ts-ignore
    const filteredTags = useSelector((state) => state.filters.tags)

    const tagsToDisplay = tags.tags

    const spanishButtonProps = languages.includes(LANGUAGE_SPANISH) ? { backgroundColor: theme.palette.primary.dark, color: '#FFF' } : {}
    const englishButtonProps = languages.includes(LANGUAGE_ENGLISH) ? { backgroundColor: theme.palette.primary.dark, color: '#FFF'  } : {}

    const handleOnClickSpanish = (e:any) => {
        dispatch(updateFilterLanguage({ language: LANGUAGE_SPANISH}))
    }

    const handleOnClickEnglish = (e:any) => {
        dispatch(updateFilterLanguage({ language: LANGUAGE_ENGLISH}))
    }

    const handleOnClickTag = ({id}: Tag) => {
        dispatch(updateFilterTag({id}))
    }

    return {
        handleOnClickSpanish,
        handleOnClickEnglish,
        handleOnClickTag,
        spanishButtonProps,
        englishButtonProps,
        tagsToDisplay,
        filteredTags
    }
}