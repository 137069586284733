import { ThemeProvider as MuiThemeProvider, responsiveFontSizes } from '@mui/material/styles'
import { CssBaseline } from '@mui/material'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { Provider } from 'react-redux'

import { theme } from './styles/theme'

import Home from './screens/Home'
import MyPosts from './screens/MyPosts'
import AboutUs from './screens/AboutUs'
import ToPlay from './screens/ToPlay'
import ToPlayViewer from './screens/ToPlayViewer'
import GraderiaMorada from './screens/GraderiaMorada'
import { Ticket } from './screens/Ticket'

import store from './store'
import {
  ROUTE_ABOUT,
  ROUTE_GRADERIA_MORADA,
  ROUTE_HOME,
  ROUTE_MY_POSTS,
  ROUTE_TICKET,
  ROUTE_TO_PLAY_VIEWER,
  ROUTE_TO_PLAY,
} from './constant'

export const App = () => {
  const themeToUse = responsiveFontSizes(theme)
  return (
    <Provider store={store}>
      <MuiThemeProvider theme={themeToUse}>
        <CssBaseline />
        <Router>
          <Routes>
            <Route path={ROUTE_HOME} element={<Home />} />
            <Route path={ROUTE_MY_POSTS} element={<MyPosts />} />
            <Route path={ROUTE_ABOUT} element={<AboutUs />} />
            <Route path={ROUTE_TO_PLAY} element={<ToPlay />} />
            <Route path={ROUTE_TO_PLAY_VIEWER} element={<ToPlayViewer />} />
            <Route path={ROUTE_GRADERIA_MORADA} element={<GraderiaMorada />} />
            <Route path={ROUTE_TICKET} element={<Ticket />} />
          </Routes>
        </Router>
      </MuiThemeProvider>
    </Provider>
  )
}
