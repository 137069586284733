import {getArticles} from "../services";
import {addArticles} from "../store/actions";
import {useEffect} from "react";
import {filterByLanguages} from "../utils/filterByLanguages";
import {filterByTags} from "../utils/filterByTags";
import {useSelector} from "react-redux";

interface Props {
    dispatch: Function
}

export const useGetArticles = ({dispatch}: Props) => {
    let articlesToDispalay = []
    const {articles} = getArticles()

    // @ts-ignore
    const filteredLanguages = useSelector((state) => state.filters.languages)
    // @ts-ignore
    const filteredTags = useSelector((state) => state.filters.tags)

    useEffect(() => {
        articlesToDispalay = filterByLanguages({languages: filteredLanguages, items: articles})
        articlesToDispalay = filterByTags({tags:filteredTags, items: articlesToDispalay})
        dispatch(addArticles({articles: articlesToDispalay}))
    }, [filteredLanguages, filteredTags])
}