import {ADD_ARTICLES} from "./actions";
import {Article} from "../../types";

interface Props {
    articles: Article[]
}

export const addArticles = ({ articles }: Props) => {
    return {
        type: ADD_ARTICLES,
        payload: articles
    }
}