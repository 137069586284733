import {ADD_TOOLS} from "./actions";
import {Tool} from "../../types";

interface Props {
    tools: Tool[]
}

export const addTools = ({ tools }: Props) => {
    return {
        type: ADD_TOOLS,
        payload: tools
    }
}