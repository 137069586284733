import styled from "@emotion/styled";
import {Grid, Typography} from "@mui/material";

import {Article} from "../types";

import {Trim} from "../utils/trim";
import {TagItem} from "./TagItem";

interface Props {
    article: Article
}

export const ReferenceItem = ({article}: Props) => {
    const {title, description, url, tags} = article

    const onClick = () => {
        window.open(url, '_blank')
    }

    return (
        <Container
            container
            onClick={onClick}
            flexDirection={"column"}
        >
            <Title variant="body2" className={'title'}>{title}</Title>
            <Description variant="caption" className={'description'}>{Trim({text: description})}</Description>
            <DetailsSectionContainer container className={'opacity-manager'}>
                <Grid container className={'opacity-manager'}>
                    {tags.map((tag) => {
                        return <TagItem key={tag} id={tag}/>
                    })}
                </Grid>
            </DetailsSectionContainer>
        </Container>
    )
}

const Container = styled(Grid)`
  padding: 5px 0;
  margin: 5px 0;
  //opacity: 0.7;

  &:hover {
    cursor: pointer;

    .title {
      color: ${({theme}) => theme.palette.primary.dark}
    }

    .description {
      color: ${({theme}) => theme.palette.text.primary}
    }

    .opacity-manager {
      opacity: 1;
    }
  }
`

const Title = styled(Typography)`
  margin-bottom: 0;
  font-family: 'Lato', sans-serif;
  font-weight: 900;
  color: ${({theme}) => theme.palette.primary.main};
  margin-right: 5px;
`


const Description = styled(Typography)`
  color: ${({theme}) => theme.palette.text.secondary};
  font-family: 'Lato', sans-serif;
`

const DetailsSectionContainer = styled(Grid)`
  margin-top: 5px;
  opacity: 0.7;
  flex-wrap: nowrap;
`
