import styled from '@emotion/styled'

import { Grid, Typography } from '@mui/material'

export const ExpertiseBox = ({ id, name, description }) => {
  return (
    <Content key={id} item columnSpacing={2} xs={12} sm={5}>
      <Grid container>
        <TitleContainer item display="flex">
          <IdLabel variant="body1">{id}</IdLabel>
          <NameLabel variant="body1">{name}</NameLabel>
        </TitleContainer>
        <Grid item>
          <DescriptionLabel variant="body1">{description}</DescriptionLabel>
        </Grid>
      </Grid>
    </Content>
  )
}

const Content = styled(Grid)`
  padding: 0 20px;
  margin-bottom: 40px;
`

const TitleContainer = styled(Grid)`
  margin-bottom: 5px;
`

const IdLabel = styled(Typography)`
  color: #e0a80d;
  font-weight: 600;
  margin-right: 10px;
`

const NameLabel = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.secondary};
  font-weight: 600;
  text-transform: uppercase;
`

const DescriptionLabel = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.secondary};
  font-weight: 400;
`
