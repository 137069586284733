import { useEffect } from 'react'
import { Grid } from '@mui/material'

export const QRCode = ({ code }) => {
  const displayQR2 = () => {
    window.$('#qr-container').qrcode({
      render: 'canvas',
      text: `${code}`,
      size: 225,
      minVersion: 1,
      maxVersion: 2,
      ecLevel: 'L',
      quiet: 0,
    })
  }

  useEffect(() => {
    // @ts-ignore
    displayQR2()
  }, [])

  return <Grid id="qr-container"></Grid>
}
