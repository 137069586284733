import styled from '@emotion/styled'
import { Grid } from '@mui/material'
import { ChronologicalInformation, Content } from '../components'

interface Props {
  pair?: boolean
}

const TITLE = 'EXPERIENCE'
const SUBTITLE = `yes, I've been around`

const DATA = [
  {
    id: 1,
    initialDate: 'May 2023',
    finalDate: 'Present',
    organization: 'Thoropass',
    title: 'Staff Software Engineer | Tech Lead',
    country: 'New York, USA',
    details: [
      {
        id: 1,
        text: `As a Staff Engineer, I blend technical acumen with soft skills evaluation, playing an active role in the hiring process
        through conducting technical interviews and assessing candidates' interpersonal skills to ensure compatibility with our team's
        dynamics. My technical responsibilities are broad, with a key focus on developing the front-end architecture for new features
        and product implementations, ensuring scalability and maintainability. I further extend my expertise by conducting research
        and developing Proof of Concepts (PoCs) to deliver viable solutions that meet our project goals.`,
      },
      {
        id: 2,
        text: `My daily responsibilities are anchored in performing thorough code reviews on pull requests to maintain high standards
        of quality and best practices. Emphasizing collaborative problem-solving, I engage in pair programming to effectively address
        and resolve technical challenges, fostering a team-oriented approach to innovation.`,
      },
      {
        id: 3,
        text: `A significant part of my role involves addressing technical debt, dedicating effort to refine and optimize existing code
        to enhance functionality and ensure the longevity of our projects. This task, while demanding, is crucial for the continuous
        improvement and scalability of our software solutions, underscoring my commitment to excellence and sustainable development
        in our team's workflow.`,
      },
    ],
  },
  {
    id: 2,
    initialDate: 'December 2020',
    finalDate: 'May 2023',
    organization: 'Laika',
    title: 'Senior Software Engineer | Team Lead',
    country: 'New York, USA',
    details: [
      {
        id: 1,
        text: `In my current position within a compliance business application, I wear dual hats as both a developer and team leader/manager.
        On the development front, I actively engage in coding and provide support by reviewing code and assisting other developers and the
        QA resource in my team to ensure smooth progress. As a manager, I take on the responsibility of orchestrating team resources, holding
        coordination sessions with product personnel, and when necessary, stepping in to define and align on feature requirements and priorities
        in the absence of a dedicated PO. This also involves close collaboration with the design team to make strategic decisions on project
        details and implementations, ensuring our objectives are met with precision and creativity.`,
      },
      {
        id: 2,
        text: `In my role as a developer, I have gained extensive experience with a broad range of technologies and libraries,
        including React.js, React Hooks, styled-components, TypeScript, GraphQL, Django, Python, Apollo, as well as tools like GIT and Jira.`,
      },
    ],
  },
  {
    id: 3,
    initialDate: 'May 2019',
    finalDate: 'November 2020',
    organization: 'Gorilla Logic',
    title: 'Senior Web Developer',
    country: 'San José, Costa Rica',
    details: [
      {
        id: 1,
        text: `In this project, I delved into the realm of automation for luxury homes, uncovering a vast array of possibilities to enhance
        individuals' experiences in an increasingly automated world. Our goal was to simplify complex processes through software, making luxury
        living seamlessly intuitive and more accessible. This endeavor not only broadened my perspective on the potential applications of
        technology in everyday life but also reinforced the importance of user-centric design in creating impactful solutions.`,
      },
      {
        id: 2,
        text: `As a software developer within this project, I actively employed cutting-edge technologies such as React.js, Hooks, TypeScript,
        and ES6, along with Easy-Peasy for state management—a derivative of Redux—that streamlined our application's data flow. My workflow was
        further supported by tools like GIT and Jira, adhering to the Scrum methodology to ensure agile and efficient development processes.
        This role allowed me to contribute significantly to the project's success while honing my technical skills in a dynamic and collaborative environment.`,
      },
    ],
  },
  {
    id: 4,
    initialDate: 'September 2017',
    finalDate: 'May 2019',
    organization: 'Edify Software Consulting',
    title: 'Front End Developer',
    country: 'San José, Costa Rica',
    details: [
      {
        id: 1,
        text: `In my role, I contributed to educational projects, developing software aimed at enhancing learning experiences.
        We consistently strived for the highest usability standards to ensure user-friendly interfaces. This experience profoundly
        highlighted the tangible impact software can have on individuals' lives.`,
      },
      {
        id: 2,
        text: `In the development team, I've worked together on weaving in the latest technologies into our apps,
        using cool tools like JavaScript, React.js, TypeScript, Redux, Sass, Angular, and Ruby on Rails.`,
      },
    ],
  },
  {
    id: 5,
    initialDate: 'May 2015',
    finalDate: 'September 2017',
    organization: 'Prodigious',
    title: 'Front End Developer',
    country: 'San José, Costa Rica',
    details: [
      {
        id: 1,
        text: `In this company I was working on advertising campaign projects, with a keen eye on visual details, fueling my strong
        inclination towards front-end development. This marked the beginning of an extensive journey into web technologies,
        broadening my understanding of software's potential and shaping my preference for specific technologies.`,
      },
    ],
  },
]

export const Experience = ({ pair = false }: Props) => {
  return (
    <Content pair={pair} title={TITLE} subtitle={SUBTITLE}>
      <SectionContent container direction={{ xs: 'column', sm: 'row' }} padding={{ xs: '25px', sm: '50px' }}>
        {DATA.map(({ id, initialDate, finalDate, organization, title, country, details }) => (
          <ChronologicalInformation
            key={id}
            initialDate={initialDate}
            finalDate={finalDate}
            organization={organization}
            title={title}
            country={country}
            details={details}
          />
        ))}
      </SectionContent>
    </Content>
  )
}

const SectionContent = styled(Grid)`
  justify-content: space-around;
`
