import {ADD_WORKSHOPS} from "./actions";
import {Workshop} from "../../types";

interface Props {
    workshops: Workshop[]
}

export const addWorkshops = ({ workshops }: Props) => {
    return {
        type: ADD_WORKSHOPS,
        payload: workshops
    }
}