import {Workshop} from "../types";
import data from "../data/workshops/workshops.json";

interface GetWorkshopsValues {
    workshops: Workshop[]
}

export const getWorkshops = (): GetWorkshopsValues => {
    const workshops = data.workshops

    return {
        workshops
    }
}