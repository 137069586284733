import styled from '@emotion/styled'

import { Grid, Typography, Box } from '@mui/material'

export const ChronologicalInformation = ({ initialDate, finalDate, organization, title, country, details }) => {
  const datesToDisplay = finalDate ? `${initialDate} - ${finalDate}` : initialDate

  return (
    <Content container>
      <InfoContainer item sm={5}>
        <StyledBox />
        <StyledDates variant="subtitle2">{datesToDisplay}</StyledDates>
        <StyledOrganization variant="subtitle1">{organization}</StyledOrganization>
        <StyledTitle variant="subtitle2">{title}</StyledTitle>
        <StyledCountry variant="subtitle2">{country}</StyledCountry>
      </InfoContainer>
      <Grid
        item
        sm={7}
        margin={{
          xs: '0 0 0 20px',
          sm: '0',
        }}
      >
        {details.map(({ id, text }) => (
          <StyledText key={id} variant="body1">
            {text}
          </StyledText>
        ))}
      </Grid>
    </Content>
  )
}

const Content = styled(Grid)`
  border-left: 1px solid ${({ theme }) => theme.palette.secondary.main};
  margin-bottom: 10px;
  padding-bottom: 20px;
  justify-content: space-between;
`

const StyledBox = styled(Box)`
  background-color: #e0a80d;
  width: 10px;
  height: 10px;
  position: relative;
  left: -26px;
  border-radius: 5px;
`

const InfoContainer = styled(Grid)`
  padding-left: 20px;
`

const StyledDates = styled(Typography)`
  font-weight: 300;
  font-size: 13px;
  color: ${({ theme }) => theme.palette.text.secondary};
`

const StyledOrganization = styled(Typography)`
  text-transform: uppercase;
  color: ${({ theme }) => theme.palette.text.secondary};
  font-weight: 600;
`

const StyledTitle = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.secondary};
  margin-bottom: 10px;
`

const StyledCountry = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.secondary};
  margin-bottom: 5px;
  font-weight: 300;
`

const StyledText = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.secondary};
  margin-bottom: 20px;
`
