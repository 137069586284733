import React from 'react'

import styled from '@emotion/styled'
import { Grid } from '@mui/material'
import { connect } from 'react-redux'

import { useToPlay } from './useToPlay'
import { Container, EmptyValue, SocialNetwork, TitleSection, WorkshopCard } from '../../components'

interface Props {
  dispatch: Function
}

const ToPlay = ({ dispatch }: Props) => {
  const { toPlay } = useToPlay({ dispatch })

  return (
    <React.Fragment>
      <Container>
        <StyledGrid container>
          <TitleSection
            title="toPlay"
            subtitle="this is how I share knowledge with some other developers"
            titleOriginalSize="48px"
          />
          <Content container>
            {toPlay.map((item) => {
              return <WorkshopCard key={item.id} workshop={item} />
            })}
            {!toPlay.length && <EmptyValue />}
          </Content>
        </StyledGrid>
      </Container>
      <SocialNetwork />
    </React.Fragment>
  )
}

const StyledGrid = styled(Grid)`
  height: 100%;
`

const Content = styled(Grid)`
  margin-top: 40px;
`

export default connect()(ToPlay)
