import styled from '@emotion/styled'
import { keyframes } from '@emotion/react'
import { Box, Grid } from '@mui/material'

// @ts-ignore
import downArrow from '../../assets/graderiaMorada/down-arrow.svg'

export const DownArrow = () => {
  return (
    <Container container>
      <DownArrowContainer1 />
      <DownArrowContainer2 />
      <DownArrowContainer3 />
    </Container>
  )
}

const Container = styled(Grid)`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const arrow = keyframes`
 0% { opacity: 0; }
 40% { opacity: 1; }
 80% { opacity: 0; }
 100% { opacity: 0; }
`

const ImageContent = styled(Box)(() => ({
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundImage: `url(${downArrow})`,
  animationName: `${arrow}`,
  animationDuration: '1.5s',
  animationIterationCount: 'infinite',
}))

const BigImageContent = styled(ImageContent)(() => ({
  width: '30px',
  height: '40px',
}))

const SmallImageContent = styled(ImageContent)(() => ({
  width: '20px',
  height: '30px',
}))

const DownArrowContainer1 = styled(SmallImageContent)`
  animation-delay: -1s;
  position: relative;
  top: 25px;
`

const DownArrowContainer2 = styled(BigImageContent)`
  animation-delay: -0.5s;
`

const DownArrowContainer3 = styled(SmallImageContent)`
  animation-delay: 0s;
  position: relative;
  top: -22px;
`
