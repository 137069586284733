import { Button, Grid, Typography, styled } from '@mui/material'
import { keyframes } from '@emotion/react'

// @ts-ignore
import saprissa from '../../assets/graderiaMorada/correo-confirmacion-2.jpg'

import { ImageViewer } from '../../components/ImageViewer'

import { DownArrow } from '../GraderiaMorada/DownArrow'
import { useTicket } from './useTicket'
import { QRCode } from './QRCode'

export const Ticket = () => {
  // @ts-ignore
  const { silla, teamName, gameDate } = useTicket()

  if (!silla) return <></>

  const { code, name, bloque, fila, asiento } = silla

  return (
    <Container container>
      <ImageViewer src={saprissa} size="75px" width="350px" />
      <CardTicket container>
        <Header container>
          <TeamsName marginBottom={'30px'}>SAPRISSA VS {teamName}</TeamsName>
          <Location>
            <span>Estadio Ricardo Saprissa</span>
            <span>Tibás, SAJ</span>
          </Location>
          <DateTime>{gameDate}</DateTime>
        </Header>
        <SeatDetails>
          <SectionTitle>Sección</SectionTitle>
          <AccessDetails>{`ACCESO ESTE (Platea Este - ${bloque} | PUERTA 12, 13, 14, 15 Y 16)`}</AccessDetails>
          <PositionDetails container>
            <Fila item xs={6}>
              <SectionTitle>Fila</SectionTitle>
              {fila}
            </Fila>
            <Fila item xs={6}>
              <SectionTitle>Asiento</SectionTitle>
              {asiento}
            </Fila>
          </PositionDetails>
        </SeatDetails>
        <QRContainer data-testid="qr-container">
          <ColorHeader />
          <QRContent>
            <QRCode code={code} />
          </QRContent>
          <QRDetails>
            <Typography>{code}</Typography>
            <ForwardDetails>Para reenviar tu boleto inicia sesión en boleteriamorada.com</ForwardDetails>
          </QRDetails>
        </QRContainer>
        <Grid container>
          <Price>Precio Regular - PLATEA ESTE ¢12,600.00</Price>
        </Grid>
        <OrderDetails container>
          <SendButton variant="contained">Enviar boleto(s)</SendButton>
          <OrderDetailsContainer container>
            <DetailsLabelBold>Orden: 118203</DetailsLabelBold>
            <DetailsName>{name}</DetailsName>
            <DetailsLabelBold>IDE: 389955</DetailsLabelBold>
          </OrderDetailsContainer>
          <OrderDetailsContainer container>
            <DetailsLabel>1/1 de Función</DetailsLabel>
            <DetailsName>
              <DownArrow />
            </DetailsName>
            <DetailsLabel>1/1 de Orden</DetailsLabel>
          </OrderDetailsContainer>
        </OrderDetails>
      </CardTicket>
    </Container>
  )
}

const Container = styled(Grid)`
  /* height: 100vh; */
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: auto;
  background: #d1d1d1;
`

const CardTicket = styled(Grid)`
  max-width: 680px;
  background-color: white;
  width: 90%;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 5px 15px;
  margin: 10px auto 35px auto;
  padding-bottom: 15px;
`

const Header = styled(Grid)`
  display: flex;
  flex-direction: column;
  padding: 10px 20px 5px 20px;
  background-color: #770037;
  color: white;
  border-radius: 10px 10px 0 0;
`

const TeamsName = styled(Typography)`
  margin-bottom: 15px;
  font-size: 17px;
  font-family: 'Montserrat', sans-serif !important;
  font-weight: bold;
`

const Location = styled(Typography)`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  font-family: 'Montserrat', sans-serif !important;
  font-size: 14px;
`

const DateTime = styled(Location)`
  font-weight: bold;
`

const SeatDetails = styled(Grid)`
  padding: 20px;
`

const SectionTitle = styled(Typography)`
  font-weight: bold;
  font-size: 14px;
`

const AccessDetails = styled(Typography)`
  font-size: 17px;
`

const PositionDetails = styled(Grid)`
  display: flex;
  flex-direction: row;
  border-top: dashed 1px;
  margin-top: 10px;
`

const Fila = styled(Grid)`
  display: flex;
  flex-direction: column;
  padding-top: 10px;
`

const QRContainer = styled(Grid)`
  display: flex;
  margin: auto;
  flex-direction: column;
  padding: 20px;
`

const verificador = keyframes`
 0% { background-position: 0% 50%; }
 50% { background-position: 100% 50%; }
 100% { background-position: 0% 50%; }
`

const ColorHeader = styled(Grid)`
  width: 225px;
  height: 25px;
  border-radius: 10px 10px 0 0;
  animation-name: ${verificador};
  animation-duration: 3s;
  animation-iteration-count: infinite;
  /* animation: 3s ease 0s infinite normal none running; */
  background: #5d0e8b linear-gradient(90deg, #fd8204, #06a7e2, #5d0e8b) repeat scroll 0% 0%/300% 300%;
`

const QRContent = styled(Grid)`
  height: 225px;
  width: 225px;
  overflow: hidden;
`

const QRDetails = styled(Grid)`
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 225px;
`

const ForwardDetails = styled(Typography)`
  font-size: 12px;
`

const Price = styled(SectionTitle)`
  display: flex;
  margin: auto;
  margin-bottom: 5px;
`

const OrderDetails = styled(Grid)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  width: 90%;
  margin: auto;
`

const OrderDetailsContainer = styled(Grid)`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
`

const SendButton = styled(Button)`
  background-color: #6895d6;
  color: white;
  font-size: 9px;
  width: 145px;
  height: 40px;
  font-family: 'Montserrat', sans-serif !important;
  text-transform: none;
  font-weight: 600;
  margin-top: 10px;
`

const DetailsName = styled(Typography)`
  font-size: 14px;
  text-transform: uppercase;
  max-width: 130px;
  text-align: center;
`

const DetailsLabel = styled(Typography)`
  font-size: 12px;
`

const DetailsLabelBold = styled(Typography)`
  font-weight: bold;
  font-size: 12px;
`
